import { useMemo } from 'react';
import {
  MoveResult,
  PlayerResult,
  PromptResult,
  RoomResult
} from '@shared/api-dto';
import {
  FactOrFibText,
  FactOrFibValue,
  GuessType,
  MoveType,
  PromptAnswerUnits,
  PromptFormat,
  PromptFormatName
} from '@shared/constants';

export const getTeamName = (teamNum: number) => {
  return teamNum === 1 ? 'Blue' : 'Orange';
};

export const useTeams = (players: PlayerResult[]) => {
  return useMemo(() => {
    const team1: PlayerResult[] = [];
    const team2: PlayerResult[] = [];
    players.forEach(p => {
      if (p.team === 1) {
        team1.push(p);
      } else if (p.team === 2) {
        team2.push(p);
      }
    });
    return [team1, team2];
  }, [players]);
};

export type ResponseCount = {
  text: string;
  count: number;
};

export type CorrectresponseCountsByPrompt = Record<string, string>;
export type AnswersByUser = Record<string, string>;
export type UserAnswersByPrompt = Record<string, AnswersByUser>;
export type ScoresByTeamNumber = Record<number, number>;
export type ScoresByPlayerGuid = Record<string, number>;
export type AllPlayAverages = Record<string, Record<string, number>>;

export type Tally = {
  currentResponseCounts: ResponseCount[];
  correctAnswers: CorrectresponseCountsByPrompt;
  correctAnswer?: string;
  scoresByTeam: ScoresByTeamNumber;
  scoresByPlayer: ScoresByPlayerGuid;
  userAnswersByPrompt: UserAnswersByPrompt;
  allPlayAvgByPrompt: AllPlayAverages;
  prePrompts: PromptResult[];
};

export type TalliesType = (roomData: RoomResult) => Tally;

export const useTallies: TalliesType = (roomData) => {
  const prePrompts = useMemo(() => (
    roomData.prompts.filter(p => p.type !== PromptFormat.SLIDER)
  ), [roomData.prompts]);

  const promptsMap = useMemo(() => {
    const pm: Record<string, PromptResult> = {};
    roomData.prompts.forEach(p => (pm[p.guid] = p));
    return pm;
  }, [roomData.prompts]);

  const [responseCountsByPrompt, userAnswersByPrompt] = useMemo(() => {
    // answer counts by prompt guid, then choice text.
    const abp: Record<string, Record<string, number>> = {};
    const aup: Record<string, AnswersByUser> = {};
    roomData.responses.forEach(r => {
      const p = promptsMap[r.promptGuid];
      if (!p) {
        console.error('Prompt not found for response', r, promptsMap);
        return;
      }

      if (!abp[r.promptGuid]) {
        abp[r.promptGuid] = {};
      }
      if (!abp[r.promptGuid][r.text]) {
        abp[r.promptGuid][r.text] = 0;
      }
      ++abp[r.promptGuid][r.text];

      if (!aup[r.promptGuid]) {
        aup[r.promptGuid] = {};
      }
      aup[r.promptGuid][r.userGuid] = r.text;
    });
    return [abp, aup];
  }, [promptsMap, roomData.responses]);

  const movesByPrompt = useMemo(() => {
    const mbp: Record<string, MoveResult> = {};
    roomData.moves?.forEach(m => {
      mbp[m.prompt.guid] = m;
    });
    return mbp;
  }, [promptsMap, roomData.moves, roomData.prompts]);

  const currentResponseCounts: ResponseCount[] = useMemo(() => {
    if (!roomData.currentMove) {
      return [];
    }
    const ca = responseCountsByPrompt[roomData.currentMove.prompt.guid];
    if (!ca) {
      return [];
    }

    return Object.entries(ca).map(([text, count]) => ({
      text,
      count
    }));
  }, [responseCountsByPrompt, roomData.currentMove?.prompt.guid]);

  const correctAnswers = useMemo(() => {
    const cas: Record<string, string> = {};
    Object.entries(responseCountsByPrompt).forEach(([promptGuid, answerMap]) => {
      // Check for Fact of Fib and use that data for correct answer.
      if (movesByPrompt[promptGuid]?.type === MoveType.FREE_FACT_OR_FIB) {
        cas[promptGuid] = movesByPrompt[promptGuid].factOrFib?.isFact ? FactOrFibValue.FACT : FactOrFibValue.FIB;
        return;
      }

      // All play prompts already have correct answer.
      if (movesByPrompt[promptGuid]?.type === MoveType.SLIDER_ALL_PLAY) {
        return;
      }

      let tie = false;
      let top = '';

      Object.entries(answerMap).forEach(([text, count]) => {
        if (!top || count > answerMap[top]) {
          top = text;
          tie = false;
        } else if (count === answerMap[top] && top !== text) {
          tie = true;
        }
      });

      if (tie) {
        top = GuessType.MULTIPLE_TIE;
      }

      cas[promptGuid] = top;
    });

    return cas;
  }, [movesByPrompt, responseCountsByPrompt, roomData.currentMove?.prompt.guid]);

  const [team1, team2] = useTeams(roomData.players);
  const allPlayAvgByPrompt = useMemo(() => {
    const apbp: AllPlayAverages = {};
    roomData.moves?.forEach(m => {
      if (m.type !== MoveType.SLIDER_ALL_PLAY) {
        return;
      }

      const getTeamSum = (team: PlayerResult[]) => {
        return team.reduce((acc, p) => {
          const response = userAnswersByPrompt[m.prompt.guid]?.[p.user.guid];
          if (!response) {
            return acc;
          }

          const val = parseInt(response, 10);
          if (!val || isNaN(val)) {
            return acc;
          }

          return acc + val;
        }, 0);
      };

      const team1Sum = getTeamSum(team1);
      const team2Sum = getTeamSum(team2);

      apbp[m.prompt.guid] = {
        1: (team1Sum / team1.length),
        2: (team2Sum / team2.length)
      };
    });
    return apbp;
  }, [roomData.moves, userAnswersByPrompt]);

  const [scoresByTeam, scoresByPlayer] = useMemo(() => {
    const sbt: ScoresByTeamNumber = {};
    const sbp: ScoresByPlayerGuid = {};

    sbt[1] = 0;
    sbt[2] = 0;

    roomData.moves?.forEach(m => {
      if (m.type === MoveType.SLIDER_ALL_PLAY) {
        const [team1Diff, team2Diff] = getAllPlayDiffs(m.prompt, allPlayAvgByPrompt);

        if (team1Diff < team2Diff) {
          sbt[1] += 2;
        } else if (team2Diff < team1Diff) {
          sbt[2] += 2;
        }
        return;
      }

      const userGuid = m.player?.user.guid;
      const p = m.player;

      if (!p || !p.team) {
        // TODO: figure out a way to tally All Play rounds.
        return;
      }
      if (userGuid && !sbp[userGuid]) {
        sbp[userGuid] = 0;
      }
      if (m.text === correctAnswers[m.prompt.guid]) {
        userGuid && ++sbp[userGuid];
        ++sbt[p.team];
      }
    });

    return [sbt, sbp];
  }, [correctAnswers, roomData.moves]);

  return {
    currentResponseCounts,
    correctAnswers,
    correctAnswer: roomData.currentMove ? correctAnswers[roomData.currentMove.prompt.guid] : undefined,
    scoresByTeam,
    scoresByPlayer,
    userAnswersByPrompt,
    allPlayAvgByPrompt,
    prePrompts
  };
};

export const useFactOrFibRadios = (
  roomData: RoomResult,
  choice: string | null
) => {
  return useMemo(() => (
    [FactOrFibValue.FACT, FactOrFibValue.FIB].map(v => ({
      text: FactOrFibText[v],
      value: v,
      checked: v === choice
    }))
  ), [choice]);
};

export const getRandomPlayers = (team: PlayerResult[], count: number = 1) => {
  const t = [...team];
  const randos = [];
  while (count > 0) {
    const i = Math.floor(Math.random() * t.length);
    const [p] = t.splice(i, 1);
    randos.push(p);
    --count;
  }
  return randos;
};

export const isMCMove = (roomData: RoomResult) => (
  roomData.currentMove?.type === MoveType.MC_READ_THE_ROOM
);

export const isFoFibMove = (roomData: RoomResult) => (
  roomData.currentMove?.type === MoveType.FREE_FACT_OR_FIB
);

export const getNextPromptIndex = (roomData: RoomResult) => (
  roomData.move - 1 - roomData.prompts.slice(0, roomData.move - 1)
    .reduce((acc, p) => (
      acc + (p.type === PromptFormat.FREE_INPUT ? 1 : 0)
    ), 0)
);

export const isGameOver = (roomData: RoomResult) => (
  getNextPromptIndex(roomData) >= roomData.prompts.length
);

export const isPreMove = (roomData: RoomResult) => (
  !roomData.currentMove && !isGameOver(roomData)
);

export const isAllPlay = (roomData: RoomResult) => (
  roomData.currentMove?.prompt.type === PromptFormat.SLIDER
);

export const getTeamUp = (roomData: RoomResult) => {
  // No team is up for all play.
  if (isAllPlay(roomData)) {
    return 0;
  }

  return (roomData.move % 2 === 1) ? 1 : 2;
};

export const teamIsMoving = (teamNum: number, roomData: RoomResult) => (
  !isPreMove(roomData) && !isGameOver(roomData) && (
    getTeamUp(roomData) === teamNum || isAllPlay(roomData)
  )
);

export const whileMoving = (roomData: RoomResult) => (
  roomData.currentMove && roomData.timeLeft
);

export const moveWasSubmitted = (roomData: RoomResult) => (
  !!roomData.currentMove?.submitted
);

export const getNextPrompt = (roomData: RoomResult) => (
  roomData.prompts[getNextPromptIndex(roomData)]
);

export const getRoundName = (roomData: RoomResult) => (
  PromptFormatName[getNextPrompt(roomData).type]
);

export const allPlayersResponded = (
  promptGuid: string,
  userAnswersByPrompt: UserAnswersByPrompt,
  roomData: RoomResult
) => {
  const answers = userAnswersByPrompt[promptGuid];
  if (!answers) {
    return false;
  }

  return Object.entries(answers).length === roomData.players.length;
};

export const getAllPlayDiffs = (
  prompt: PromptResult,
  allPlayAvgByPrompt: AllPlayAverages
) => {
  const avgs = allPlayAvgByPrompt[prompt.guid];
  if (!avgs) {
    console.error('did not calculate averages for prompt', prompt);
    return [];
  }

  const team1Avg = allPlayAvgByPrompt[prompt.guid][1];
  const team2Avg = allPlayAvgByPrompt[prompt.guid][2];
  const answer = prompt.answer?.value;

  if (typeof answer === 'undefined') {
    console.error('could not find answer for all play', prompt);
    return [];
  }

  const team1Diff = Math.abs(answer - team1Avg);
  const team2Diff = Math.abs(answer - team2Avg);
  return [team1Diff, team2Diff];
};

export const toUnitString = (value: number, units?: PromptAnswerUnits) => {
  const num = value.toLocaleString();

  switch (units) {
    case PromptAnswerUnits.PERCENTAGE:
      return num + '%';
    case PromptAnswerUnits.USD:
      return '$' + num;
    default:
      return num;
  }
};

export const useNextPlayer = (roomData: RoomResult) => {
  const [team1, team2] = useTeams(roomData.players);
  const teamUp = getTeamUp(roomData);

  if (teamUp === 0) {
    return undefined;
  }

  const team = teamUp === 1 ? team1 : team2;
  return team[Math.floor((roomData.move - 1) / 2)];
};
