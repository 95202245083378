import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createRoom } from '../api/endpoints';

export const Landing = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const joinRef = useRef<HTMLInputElement>(null);

  const onCreate = async () => {
    try {
      setError(null);
      const data = await createRoom();
      navigate(`room/${data.code}`);
    } catch (e: any) {
      setError(e);
    }
  };

  const onJoin = () => {
    const roomCode = joinRef.current?.value;
    if (!roomCode) {
      setError('Please enter a room code to join a room');
      return;
    }
    navigate(`room/${roomCode}`);
  };

  return <>
    <h1><img className='logo' src='/static/favicon.svg' />Welcome to Icebreak!</h1>
    <p>
      Icebreak is a collabortive online game meant to help you get
      to know your friends or colleages. It can be played over video
      chat or in person. To get started, create a new game below.
      Or if you already know the room code for an existing game,
      enter it to join.
    </p>
    {error && <p style={{ color: 'red' }}>{error}</p>}
    <h3>Create a new game:</h3>
    <div className="flex">
      <button onClick={onCreate}>Create Game</button>
    </div>
    <h3>Join existing game:</h3>
    <div className="flex">
      <input ref={joinRef} type="text" placeholder="enter your room code" />
      <button onClick={onJoin}>Join</button>
    </div>
  </>;
};
