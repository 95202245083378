export enum PromptFormat {
  MULTIPLE_CHOICE = 'multiple_choice',
  FREE_INPUT = 'free_input',
  SLIDER = 'slider',
}

export const MoveDurationByFormat: Record<PromptFormat, number> = {
  [PromptFormat.MULTIPLE_CHOICE]: 33,
  [PromptFormat.FREE_INPUT]: 63,
  [PromptFormat.SLIDER]: 33
};

export enum GuessType {
  MULTIPLE_TIE = '__multiple_tie__',
  MULTIPLE_SELECTION = '__multiple_selection__',
  FREE_TRUTH = '__free_truth__',
  FREE_LIE = '__free_lie__',
}

export enum MoveType {
  MC_READ_THE_ROOM = 'mc_read_the_room',
  FREE_FACT_OR_FIB = 'free_fact_or_fib',
  SLIDER_ALL_PLAY = 'slider_all_play',
}

export enum FactOrFibValue {
  FACT = 'fofv_fact',
  FIB = 'fofv_fib',
}

export const FactOrFibText: Record<string, string> = {
  [FactOrFibValue.FACT]: 'Both facts',
  [FactOrFibValue.FIB]: 'Both fibs'
};

export const TIE = 'It was a tie';

export const PromptFormatName = {
  [PromptFormat.FREE_INPUT]: 'Fact or Fib',
  [PromptFormat.MULTIPLE_CHOICE]: 'Read the Room',
  [PromptFormat.SLIDER]: 'Everyone Plays!'
};

export enum PromptAnswerUnits {
  USD = 'usd',
  PERCENTAGE = 'percentage',
  NUMBER = 'number',
}

export enum NetErrorType {
  KICKED = 'kicked',
}
