import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
  FactOrFibText,
  GuessType,
  MoveDurationByFormat,
  MoveType,
  PromptFormat,
  TIE
} from '@shared/constants';
import { ActivateFactOrFibData, PlayerResult, RoomResult } from '@shared/api-dto';
import {
  AllPlayAverages,
  allPlayersResponded,
  getAllPlayDiffs,
  getNextPrompt,
  getRandomPlayers,
  getRoundName,
  getTeamName,
  getTeamUp,
  isAllPlay,
  isFoFibMove,
  isGameOver,
  isMCMove,
  isPreMove,
  moveWasSubmitted,
  ResponseCount,
  ScoresByTeamNumber,
  teamIsMoving,
  toUnitString,
  useFactOrFibRadios,
  useNextPlayer,
  UserAnswersByPrompt,
  useTallies,
  useTeams,
  whileMoving
} from './shared/room-data-helpers';
import { activateMove, endMove, playAgain, submitMove } from '../../api/endpoints';
import { Marquee, MarqueeItem } from './shared/marquee';
import { Timer } from './shared/timer';
import { RadioGroup } from './shared/radio-group';
import { PieChart } from './shared/pie';
import { Modal } from './shared/modal';
import { TeamList } from './shared/team-list';
import { Confetti } from './shared/confetti';

// Little helper function.
const quote = (text: string) => (`"${text}"`);

type GameRoomProps = {
  roomData: RoomResult;
};

export const GameRoom = ({ roomData }: GameRoomProps) => {
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [team1, team2] = useTeams(roomData.players);

  const teamUp = getTeamUp(roomData);
  const nextPrompt = getNextPrompt(roomData);
  const nextPlayer = useNextPlayer(roomData);

  const {
    currentResponseCounts,
    correctAnswer,
    scoresByPlayer,
    scoresByTeam,
    userAnswersByPrompt,
    allPlayAvgByPrompt
  } = useTallies(roomData);

  // Check for All Play complete.
  useEffect(() => {
    if (!roomData.currentMove || roomData.currentMove.type !== MoveType.SLIDER_ALL_PLAY) {
      return;
    }

    if (roomData.currentMove.submitted) {
      return;
    }

    if (allPlayersResponded(roomData.currentMove.prompt.guid, userAnswersByPrompt, roomData)) {
      (async () => {
        try {
          await submitMove({
            code: roomData.code,
            promptGuid: roomData.currentMove!.prompt.guid
          });
        } catch (e) {
          console.error('could not submit choice', e);
        }
      })();
    }
  }, [roomData.currentMove?.prompt.guid, userAnswersByPrompt, roomData.players]);

  const onModalCancel = () => {
    setModalActive(false);
  };

  const onModalConfirm = async () => {
    setModalActive(false);
    try {
      const { newCode } = await playAgain({ code: roomData.code });
      window.location.href = `/room/${newCode}`;
    } catch (e) {
      console.error('could not play again', e);
    }
  };

  const isTeamUp = (teamNumber: number) => {
    if (!roomData.currentMove) {
      return false;
    }

    return teamNumber === teamUp;
  };

  const setUpMove = async () => {
    try {
      let factOrFibData: ActivateFactOrFibData | undefined;
      if (nextPrompt.type === PromptFormat.FREE_INPUT) {
        const otherTeam = nextPlayer!.team === 1 ? team2 : team1;
        const [p1, p2] = getRandomPlayers(otherTeam, 2);
        factOrFibData = {
          firstResponderGuid: p1.user.guid,
          secondResponderGuid: p2.user.guid,
          isFact: !!Math.floor(Math.random() * 2)
        };
      }
      await activateMove({
        code: roomData.code,
        playerGuid: nextPlayer?.user.guid,
        promptGuid: nextPrompt.guid,
        duration: MoveDurationByFormat[nextPrompt.type],
        factOrFibData
      });
    } catch (e) {
      console.error('failed to activate player', e);
    }
  };

  const tearDownMove = async () => {
    if (!roomData.currentMove?.prompt) {
      console.error('no move to end');
      return;
    }

    try {
      await endMove({
        code: roomData.code,
        promptGuid: roomData.currentMove?.prompt.guid
      });
    } catch (e) {
      console.error('failed to tear down move', e);
    }
  };

  const onTimeUp = async () => {
    if (!roomData.currentMove) {
      console.error('time ran out without current move');
      return;
    }

    try {
      await submitMove({
        code: roomData.code,
        promptGuid: roomData.currentMove.prompt.guid
      });
    } catch (e) {
      console.error('could not submit choice', e);
    }
  };

  const onGameOver = () => {
    setModalActive(true);
  };

  const getTeamPanel = (teamNum: number) => {
    let className = teamNum === 1 ? 'leftTeamContainer' : 'rightTeamContainer';
    if (isTeamUp(teamNum)) {
      className += ' full';
    }

    return (
      <div className={className}>
        {teamIsMoving(teamNum, roomData)
          ? (
            <>
              {moveWasSubmitted(roomData) && !isAllPlay(roomData) && (
                <EndMoveMarquee
                  roomData={roomData}
                  correctAnswer={correctAnswer}
                  currentResponseCounts={currentResponseCounts}
                  userAnswersByPrompt={userAnswersByPrompt}
                  onComplete={tearDownMove} />
              )}
              {moveWasSubmitted(roomData) && isAllPlay(roomData) && (
                <EndMarqueeAllPlay
                  roomData={roomData}
                  userAnswersByPrompt={userAnswersByPrompt}
                  allPlayAvgByPrompt={allPlayAvgByPrompt}
                  onComplete={tearDownMove} />
              )}
              {whileMoving(roomData) && !moveWasSubmitted(roomData) && (
                <Timer key={'timer' + roomData.currentMove!.prompt.guid}
                  initialSeconds={roomData.timeLeft! / 1000}
                  onComplete={onTimeUp} />
              )}
              {roomData.currentMove?.type === MoveType.FREE_FACT_OR_FIB && (
                <FactOrFibPanel
                  roomData={roomData}
                  userAnswersByPrompt={userAnswersByPrompt} />
              )}
              {roomData.currentMove?.type === MoveType.MC_READ_THE_ROOM && (
                <MultiChoicePanel roomData={roomData} />
              )}
              {roomData.currentMove?.type === MoveType.SLIDER_ALL_PLAY && (
                <AllPlayPanel
                  roomData={roomData}
                  team={teamNum === 1 ? team1 : team2}
                  userAnwersByPrompt={userAnswersByPrompt}
                  name={`Team ${getTeamName(teamNum)}`} />
              )}
            </>
            )
          : (
            <>
              <h3>{`Team ${getTeamName(teamNum)}`}</h3>
              <TeamList team={teamNum === 1 ? team1 : team2} scores={scoresByPlayer} />
              <hr className='scoreDivider' />
              <p className='scoreContainer'>{`Score: ${scoresByTeam[teamNum] ?? 0}`}</p>
            </>
            )}
      </div>
    );
  };

  return (
    <>
      {isGameOver(roomData) && (
        <>
          <GameOverMarquee
            roomData={roomData}
            scoresByTeam={scoresByTeam}
            onComplete={onGameOver} />
        </>
      )}
      {isPreMove(roomData) && (
        <StartMoveMarquee
          roomData={roomData}
          nextPlayer={nextPlayer}
          onComplete={setUpMove} />
      )}
      {getTeamPanel(1)}
      {getTeamPanel(2)}
      {!isPreMove(roomData) && isAllPlay(roomData) && (
        <div className='allPlayOverlay'>
          <h1><u>Everyone</u></h1>
          <h3><i>{roomData.currentMove?.prompt.text}</i></h3>
          <p>
            Give your best guess on your phone.<br />
            The team with the closest <b>average</b> wins!
          </p>
        </div>
      )}
      <Modal
        isActive={modalActive}
        message='Play Again?'
        confirmMessage='Yes!'
        onCancel={onModalCancel}
        onConfirm={onModalConfirm} />
    </>
  );
};

type AllPlayPanelProps = {
  roomData: RoomResult;
  team: PlayerResult[];
  userAnwersByPrompt: UserAnswersByPrompt;
  name: string;
};

const AllPlayPanel = ({ roomData, team, userAnwersByPrompt, name }: AllPlayPanelProps) => {
  const userAnswers = userAnwersByPrompt[roomData.currentMove!.prompt.guid];
  const radios = useMemo(() => (
    team.map(p => ({
      text: p.user.name,
      checked: !!userAnswers?.[p.user.guid],
      name: p.user.name
    }))
  ), [team, userAnswers]);

  useEffect(() => {

  }, [userAnswers]);

  return (
    <>
      <div className='allPlay'>
        <div className='allPlayTeamName'>{name}</div>
        <RadioGroup radios={radios} name={name} />
      </div>
    </>
  );
};

type FactOrFibPanelProps = {
  roomData: RoomResult;
  userAnswersByPrompt: UserAnswersByPrompt;
};

const FactOrFibPanel = ({
  roomData,
  userAnswersByPrompt
}: FactOrFibPanelProps) => {
  if (!roomData.currentMove?.factOrFib) {
    console.error('rendering fact or fib panel without data');
    return null;
  }

  const player = roomData.currentMove.player;
  const {
    firstResponder,
    secondResponder,
    isFact
  } = roomData.currentMove.factOrFib;

  const move = roomData.currentMove;
  const prompt = move.prompt;
  const firstName = firstResponder.user.name;
  const secondName = secondResponder.user.name;

  const radios = useFactOrFibRadios(roomData, roomData.currentMove?.text || null);

  const [firstReponse, secondResponse] = useMemo(() => {
    const answers = userAnswersByPrompt[prompt.guid];
    const first = answers[firstResponder.user.guid];
    const second = answers[secondResponder.user.guid];
    return isFact ? [first, second] : [second, first];
  }, []);

  if (!player) {
    console.error('fact or fib rendered without player');
    return null;
  }

  return (
    <>
      <h2>Player: <u>{player.user.name}</u></h2>
      <p className='promptContainer'>When asked: <i>{quote(prompt.text)}</i></p>
      <div className='quoteContainer'>
        <div>
          <p>Did <b>{firstName}</b> say:</p>
          <div className='quote'>{quote(firstReponse)}</div>
        </div>
        <div>
          <p>Did <b>{secondName}</b> say:</p>
          <div className='quote'>{quote(secondResponse)}</div>
        </div>
      </div>
      <div className='moveContainer'>
        <div className='instructions'>
          <ul>
            <li><b>
              {`${player.user.name} can ask any questions to ${firstName} and ${secondName}.`}
            </b></li>
            <li>{`${firstName} and ${secondName} must answer.`}</li>
            <li>The clock is ticking!</li>
          </ul>
        </div>
        <div className='questionContainer'>
          <div className='phoneLike'>
            <p>Are these both facts or both fibs?</p>
            <RadioGroup radios={radios} />
          </div>
        </div>
      </div>
    </>
  );
};

type MultiChoicePanelProps = {
  roomData: RoomResult;
};

const MultiChoicePanel = ({ roomData }: MultiChoicePanelProps) => {
  const player = roomData.currentMove!.player;
  const radios = useMemo(() => {
    return roomData.currentMove?.prompt.choices?.map(c => ({
      text: c,
      checked: c === roomData.currentMove?.text
    }));
  }, [roomData.currentMove?.prompt.choices]);

  if (!player) {
    console.error('multi-choice panel rendered without player');
    return null;
  }

  return (
    <>
      <h2>Player: <u>{player.user.name}</u></h2>
      <div className='moveContainer'>
        <div className='instructions'>
          <ul>
            <li>{`${player.user.name} decides.`}</li>
            <li><b>Discuss with your team!</b></li>
            <li>Answer on your phone.</li>
            <li>The clock is ticking!</li>
          </ul>
        </div>
        <div className='questionContainer'>
          <p>Which of the following was the most popular answer (for both teams)?</p>
          <div className='phoneLike'>
            <p><i>{roomData.currentMove?.prompt.text}</i></p>
            <RadioGroup radios={radios ?? []} />
            <p>or...</p>
            <RadioGroup radios={[{
              text: TIE,
              value: GuessType.MULTIPLE_TIE,
              checked: roomData.currentMove?.text === GuessType.MULTIPLE_TIE
            }]} />
          </div>
        </div>
      </div>
    </>
  );
};

type StartMoveMarqueeProps = {
  roomData: RoomResult;
  onComplete: () => void;
  nextPlayer?: PlayerResult;
};

const StartMoveMarquee = ({ roomData, nextPlayer, onComplete }: StartMoveMarqueeProps) => {
  if (roomData.currentMove) {
    return null;
  }

  const teamRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (teamRef.current) {
      setTimeout(() => {
        teamRef.current!.classList.add('active');
      }, 1400);
    }
  }, [teamRef.current]);

  const move = roomData.move;
  const round = Math.ceil(move / 2);
  const teamUp = getTeamUp(roomData);
  const teamName = getTeamName(teamUp);
  const nextPrompt = getNextPrompt(roomData);

  return (
    <Marquee key={'marquee_intro' + roomData.move} onComplete={onComplete}>
      {move === 1
        ? [
        <MarqueeItem key='pick_teams' duration={4000}>
          <div ref={teamRef} className='chooseTeams'>
            <div className='teamPanel team1'></div>
            <div className='teamPanel team2'></div>
            <div className='playerHolder'>
              <div>Picking teams...</div>
              {roomData.players.map(p => (
                <div key={p.user.guid}>
                  <span className={p.team === 1 ? 'onTeam1' : 'onTeam2'}>
                    {p.user.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </MarqueeItem>,
        <MarqueeItem key='get_ready' duration={1250}>
          {'Let\'s go!'}
        </MarqueeItem>
          ]
        : []}
      {teamUp === 1
        ? [
        <MarqueeItem key='marquee_round' duration={1250}>{`Round ${round}`}</MarqueeItem>
          ]
        : []}
      <MarqueeItem duration={1250}>{getRoundName(roomData)}</MarqueeItem>
      {nextPrompt.type !== PromptFormat.SLIDER
        ? [
          <MarqueeItem key='marquee_team_up' duration={1250}>
            {`${teamName} team is up`}
          </MarqueeItem>
          ]
        : []}
      {nextPrompt.type === PromptFormat.FREE_INPUT
        ? [
          <MarqueeItem key='free_input' duration={4000}>
            <div className='quoteContainer'>
              <div className='full'>
                <b className='big'>{nextPlayer!.user.name}</b>, guess the other team&apos;s answers to:
                <div className='quote'>{nextPrompt.text}</div>
              </div>
            </div>
          </MarqueeItem>
          ]
        : [
        <MarqueeItem key='non_free_input' duration={2000}>
          {`Get ready ${nextPlayer?.user.name ?? 'everybody'}!`}
        </MarqueeItem>
          ]}
      <MarqueeItem duration={500}>Ready?</MarqueeItem>
      <MarqueeItem duration={500}>STEADY!</MarqueeItem>
      <MarqueeItem duration={800}>GO!</MarqueeItem>
    </Marquee>
  );
};

type EndMarqueeAllPlayProps = {
  roomData: RoomResult;
  userAnswersByPrompt: UserAnswersByPrompt;
  allPlayAvgByPrompt: AllPlayAverages;
  onComplete: () => void;
};
const EndMarqueeAllPlay = ({
  roomData, onComplete, userAnswersByPrompt, allPlayAvgByPrompt
}: EndMarqueeAllPlayProps) => {
  const [team1, team2] = useTeams(roomData.players);
  const prompt = roomData.currentMove!.prompt;
  const answers = userAnswersByPrompt[prompt.guid] ?? {};
  const team1Avg = toUnitString(Math.round(allPlayAvgByPrompt[prompt.guid][1]), prompt.answer?.units);
  const team2Avg = toUnitString(Math.round(allPlayAvgByPrompt[prompt.guid][2]), prompt.answer?.units);
  const [team1Diff, team2Diff] = getAllPlayDiffs(prompt, allPlayAvgByPrompt);
  let winner = 0;
  if (team1Diff < team2Diff) {
    winner = 1;
  } else if (team2Diff < team1Diff) {
    winner = 2;
  }

  const actualAnswer = toUnitString(
    roomData.currentMove?.prompt.answer?.value ?? 0,
    roomData.currentMove?.prompt.answer?.units
  );

  const getTeamItem = (teamNum: number) => (
    <div className={`endMoveSummary team${teamNum}`}>
      <p>{`${getTeamName(teamNum)} Team`}</p>
      {(teamNum === 1 ? team1 : team2).map(p => (
        <div className='summaryRow' key={`result_${p.user.guid}`}>
          <span>{p.user.name}</span>
          <span>{
            typeof answers[p.user.guid] !== 'undefined'
              ? toUnitString(parseInt(answers[p.user.guid], 10), prompt.answer?.units)
              : '--'}</span>
        </div>
      ))}
      <hr />
      <div className='summaryRow'>
        <span>Average</span>
        <span className='bold'>{`${teamNum === 1 ? team1Avg : team2Avg}`}</span>
      </div>
    </div>
  );

  const getScoreItem = (teamNum: number) => (
    <div className={`endMoveSummary team${teamNum}`}>
      <p>{`${getTeamName(teamNum)} Team`}</p>
      <div className='summaryRow'>
        <span>Answer:</span>
        <span>{actualAnswer}</span>
      </div>
      <div className='summaryRow'>
        <span>Guess:</span>
        <span className='bold'>{teamNum === 1 ? team1Avg : team2Avg}</span>
      </div>
      <hr />
      <div className='summaryRow'>
        <span>Difference:</span>
        <span className='bold red'>
          {toUnitString(Math.round(teamNum === 1 ? team1Diff : team2Diff), prompt.answer?.units)}
        </span>
      </div>
    </div>
  );

  return (
    <Marquee key={'marquee_outro' + roomData.move} onComplete={onComplete}>
      <MarqueeItem duration={2500}>
        <p>Here were your answers...</p>
      </MarqueeItem>
      <MarqueeItem duration={5300}>
        <div>
          <p className='promptSummary'>{roomData.currentMove!.prompt.text}</p>
          <div className='teamSummary'>
            {getTeamItem(1)}
            {getTeamItem(2)}
          </div>
        </div>
      </MarqueeItem>
      <MarqueeItem duration={1500}>
        <p>The actual answer was...</p>
      </MarqueeItem>
      <MarqueeItem duration={2500}>
        <h1 className='actualAnswer'>{actualAnswer}</h1>
      </MarqueeItem>
      <MarqueeItem duration={2500}>
        <p>And the results were (lower is better)...</p>
      </MarqueeItem>
      <MarqueeItem duration={5300}>
        <div>
          <p className='promptSummary'>{roomData.currentMove!.prompt.text}</p>
          <div className='teamSummary'>
            {getScoreItem(1)}
            {getScoreItem(2)}
          </div>
        </div>
      </MarqueeItem>
      {winner === 0
        ? (
        <MarqueeItem duration={3000}>
          Somehow, both averages were equal?
        </MarqueeItem>
          )
        : []}
      {winner
        ? (
          <MarqueeItem duration={3000}>
            {`2 points to the ${getTeamName(winner)} team!`}
          </MarqueeItem>
          )
        : []}
    </Marquee>
  );
};

type EndMoveMarqueeProps = {
  roomData: RoomResult;
  correctAnswer: string | undefined;
  currentResponseCounts: ResponseCount[];
  userAnswersByPrompt: UserAnswersByPrompt;
  onComplete: () => void;
};

const EndMoveMarquee = ({
  roomData,
  correctAnswer,
  currentResponseCounts,
  userAnswersByPrompt,
  onComplete
}: EndMoveMarqueeProps) => {
  const player = roomData.currentMove?.player;
  if (!player) {
    return null;
  }

  const guess = roomData.currentMove?.text;
  let prettyGuess = guess;
  let prettyAnswer = correctAnswer;
  if (isMCMove(roomData)) {
    prettyGuess = guess === GuessType.MULTIPLE_TIE ? TIE : guess;
    prettyAnswer = correctAnswer === GuessType.MULTIPLE_TIE ? TIE : correctAnswer;
  } else if (isFoFibMove(roomData)) {
    prettyGuess = FactOrFibText[guess ?? ''];
    prettyAnswer = FactOrFibText[correctAnswer ?? ''];
  }

  const {
    firstResponder,
    secondResponder
  } = roomData.currentMove?.factOrFib ?? {};

  const firstName = firstResponder?.user.name;
  const secondName = secondResponder?.user.name;

  const [firstReponse, secondResponse] = useMemo(() => {
    if (!roomData.currentMove?.factOrFib) {
      return ['', ''];
    }

    const answers = userAnswersByPrompt[roomData.currentMove.prompt.guid];
    return [
      answers[firstResponder!.user.guid],
      answers[secondResponder!.user.guid]
    ];
  }, []);

  return (
    <Marquee key={'marquee_outro' + roomData.move} onComplete={onComplete}>
      {prettyGuess
        ? ([
          <MarqueeItem key={'player_chose' + roomData.move} duration={1250}>{`${player.user.name} chose:`}</MarqueeItem>,
          <MarqueeItem key={'player_guess' + roomData.move} duration={2000}>
            <p className='answerSummary'>{quote(prettyGuess!)}</p>
          </MarqueeItem>
          ])
        : (
          <MarqueeItem duration={2200}>{`${player.user.name} ran out of time`}</MarqueeItem>
          )}
      {guess && guess === correctAnswer
        ? (
          <MarqueeItem duration={800}>{'That\'s CORRECT!'}</MarqueeItem>
          )
        : (
            (prettyGuess ? [<MarqueeItem key={1} duration={800}>WRONG!</MarqueeItem>] : []).concat([
            <MarqueeItem key={2} duration={800}>The answer was:</MarqueeItem>,
            <MarqueeItem key={3} duration={1750}>
              <p className='answerSummary'>{quote(prettyAnswer!)}</p>
            </MarqueeItem>
            ])
          )}
      {isMCMove(roomData)
        ? (
        <MarqueeItem duration={5000}>
          <div className='pieHolder'>
            <p><i>{roomData.currentMove?.prompt.text}</i></p>
            <PieChart data={currentResponseCounts} />
          </div>
        </MarqueeItem>
          )
        : []}
      {isFoFibMove(roomData)
        ? (
          <MarqueeItem duration={5000}>
            <div className='quoteContainer'>
              <div>
                <p><b className='big'>{firstName}</b> really said:</p>
                <div className='quote'>{quote(firstReponse)}</div>
              </div>
              <div>
                <p><b className='big'>{secondName}</b> really said:</p>
                <div className='quote'>{quote(secondResponse)}</div>
              </div>
            </div>
          </MarqueeItem>
          )
        : []}
    </Marquee>
  );
};

type GameOverMarqueeProps = {
  roomData: RoomResult;
  onComplete: () => void;
  scoresByTeam: ScoresByTeamNumber;
};

const GameOverMarquee = ({ roomData, onComplete, scoresByTeam }: GameOverMarqueeProps) => {
  const [team1, team2] = useTeams(roomData.players);

  if (roomData.currentMove) {
    return null;
  }

  const getCongrats = (team: PlayerResult[]) => (
    <div className='congratsSummary'>
      <div>
        {'Congratulations '}
        <>
          {team.map((p, i) => (
            <span key={'team_' + i}>
              <b key={p.user.name}>{p.user.name}</b>
              {i < team.length - 1 ? ', ' : ''}
              {i === team.length - 2 ? ' and ' : ''}
            </span>
          ))}!!!
        </>
      </div>
    </div>
  );

  const team1Score = scoresByTeam[1] ?? 0;
  const team2Score = scoresByTeam[2] ?? 0;

  let winText = 'NOBODY! (it\'s a tie)';
  let congratsNode = <>Congratulations NOBODY!</>;
  if (team1Score > team2Score) {
    winText = 'The ' + getTeamName(1) + ' team!!!';
    congratsNode = getCongrats(team1);
  } else if (team2Score > team1Score) {
    winText = 'The ' + getTeamName(2) + ' team!!!';
    congratsNode = getCongrats(team2);
  }

  return (
    <Marquee key={'marquee_outro' + roomData.move} onComplete={onComplete}>
      <MarqueeItem duration={1500}>{'And the winner is...'}</MarqueeItem>
      <MarqueeItem duration={2000}>{winText}</MarqueeItem>
      <MarqueeItem duration={3200}>
        {congratsNode}
        <Confetti color={team1Score > team2Score ? 'var(--primary-color)' : 'var(--secondary-color)'} />
      </MarqueeItem>
    </Marquee>
  );
};
