import React, { useMemo } from 'react';
import { Pie } from '@nivo/pie';
import { ResponseCount } from './room-data-helpers';

export type PieProps = {
  data: ResponseCount[];
};

export const PieChart = ({ data }: PieProps) => {
  const converted = useMemo(() => data.map(r => ({
    id: r.text,
    label: r.text,
    value: r.count
  })), []);

  return (
    <Pie
      theme={{
        fontSize: 24,
        fontFamily: 'var(--font-family)'
      }}
      height={250}
      width={250}
      data={converted}
      motionConfig="wobbly"
      isInteractive={false}
      innerRadius={0.4}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            0.2
          ]
        ]
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor='var(--font-color)'
      arcLinkLabelsThickness={3}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
    />
  );
};
