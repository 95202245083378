import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { HostRoom } from './host-room';
import { PlayerRoom } from './player-room';
import { UserResult } from '@shared/api-dto';
import { useRoomData } from '../../api/hooks';

import './room.css';

type GameRoomProps = {
  user: UserResult;
}
export const Room = ({ user }: GameRoomProps) => {
  const { code } = useParams();
  const [isLoading, error, roomData] = useRoomData(code);
  const isHost = user.guid === roomData?.host.guid;

  useEffect(() => {
    if (roomData?.nextRoomCode && !isHost) {
      window.location.href = `/room/${roomData.nextRoomCode}?skip_name=1`;
    }
  }, [roomData?.nextRoomCode, isHost]);

  return (
    <div className='roomContainer'>
      {error && (<div className='error'><span>{error}</span></div>)}
      {isLoading && (<div className='loadingSpinner'></div>)}
      {roomData && isHost && (
        <HostRoom roomData={roomData} />
      )}
      {roomData && !isHost && (
        <PlayerRoom roomData={roomData} user={user} />
      )}
    </div>
  );
};
