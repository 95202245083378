import {
  KitchenSinkResult,
  CreateRoomResult,
  RespondRequest,
  PlayerTeamRequest,
  UpdateTeamsRequest,
  ActivateMoveRequest,
  UpdateMoveRequest,
  SubmitMoveRequest,
  PlayAgainRequest,
  PlayAgainResult,
  KickRequest
} from '@shared/api-dto';

const BASE_URL = '/api/';
const BASE_OPTIONS: RequestInit = {
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json'
  }
};

async function get (stub: string) {
  const response = await fetch(BASE_URL + stub, BASE_OPTIONS);
  const result = await response.json();
  if (response.ok) {
    return result;
  }

  throw result.error || 'unknown error in ' + stub;
}

async function post (stub: string, data: Record<string, any>) {
  const response = await fetch(BASE_URL + stub, {
    ...BASE_OPTIONS,
    method: 'POST',
    body: JSON.stringify(data)
  });
  const result = await response.json();
  if (response.ok) {
    return result;
  }

  throw result.error || 'unknown error when POST to ' + stub;
}

export async function getKitchenSink (): Promise<KitchenSinkResult> {
  return get('kitchensink');
}

export async function createRoom (): Promise<CreateRoomResult> {
  return post('createroom', {});
}

export async function playAgain (payload: PlayAgainRequest): Promise<PlayAgainResult> {
  return post('playagain', payload);
}

export async function respondToPrompt (payload: RespondRequest): Promise<void> {
  return post('respond', payload);
}

export async function updateName (code: string, name: string): Promise<void> {
  return post('updatename', {
    name,
    code
  });
}

export async function updateTeams (
  code: string,
  updates: PlayerTeamRequest[]
): Promise<void> {
  const payload: UpdateTeamsRequest = {
    code,
    updates
  };
  return post('updateteams', payload);
}

export async function activateMove (payload: ActivateMoveRequest) {
  return post('activatemove', payload);
}

export async function setMove (payload: UpdateMoveRequest) {
  return post('setmove', payload);
}

export async function submitMove (payload: SubmitMoveRequest) {
  return post('submitmove', payload);
}

export async function endMove (payload: SubmitMoveRequest) {
  return post('endmove', payload);
}

export async function kickPlayer (payload: KickRequest) {
  return post('kickplayer', payload);
}
