import React from 'react';

import './modal.css';

type ModalProps = {
  isActive: boolean;
  message: string;
  confirmMessage?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export const Modal = ({
  isActive,
  message,
  confirmMessage,
  onConfirm,
  onCancel
}: ModalProps) => {
  return (
    <div onClick={onCancel} className={'modalOverlay' + (isActive ? ' active' : '')}>
      <div className='modalItself'>
        <p>{message}</p>
        <button onClick={onConfirm}>
          {confirmMessage || 'Confirm'}
        </button>
      </div>
    </div>
  );
};
