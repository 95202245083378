import React, { ChangeEvent, useCallback } from 'react';

import './radio-group.css';

export type RadioData = {
  text: string;
  value?: string;
  checked?: boolean;
  name?: string;
}

export type RadioGroupProps = {
  radios: RadioData[];
  onSelect?: (text: string) => void;
  disabled?: boolean;
  name?: string;
};

export const RadioGroup = ({ radios, onSelect, disabled, name }: RadioGroupProps) => {
  const onChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    onSelect && onSelect((evt.target as any).value);
  }, []);

  return (
    <>
      {radios.map((r, i) => (
        <div key={r.text + i} className='radiobtn'>
          <input
            disabled={!!disabled}
            onChange={onChange}
            id={'radio_' + i}
            type='radio'
            name={r.name ?? name ?? 'radios'}
            checked={r.checked}
            value={r.value ?? r.text}
          />
          <label htmlFor={'radio_' + i}>{r.text}</label>
        </div>
      ))}
    </>
  );
};
