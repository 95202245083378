import React, {
  useLayoutEffect,
  useRef
} from 'react';

import './confetti.css';

const PARTICLE_COUNT = 150;
const BASE_SPEED = 40;
const SPEED_VARIANCE = 800;
const GRAVITY = 900;

type ParticleType = {
  xPos: number;
  yPos: number;
  xVel: number;
  yVel: number;
  el?: HTMLSpanElement;
}

type ConfettiProps = {
  color: string;
}

export const Confetti = ({ color }: ConfettiProps) => {
  const particles = useRef<ParticleType[]>();
  const prevTime = useRef<number | null>(null);
  const requestRef = useRef<number | null>(null);

  if (!particles.current) {
    particles.current = [];
    while (particles.current.length < PARTICLE_COUNT) {
      const angle = Math.random() * 2 * Math.PI;
      const speed = BASE_SPEED + (Math.random() * SPEED_VARIANCE);

      particles.current.push({
        xPos: 0,
        yPos: 0,
        xVel: speed * Math.cos(angle),
        yVel: -Math.abs(speed * Math.sin(angle))
      });
    }
  }

  const animate = (timestamp: number) => {
    if (!prevTime.current) {
      prevTime.current = timestamp;
    }
    const elapsed = timestamp - prevTime.current;
    prevTime.current = timestamp;

    for (let i = 0; i < (particles.current?.length ?? 0); i++) {
      const p = particles.current![i];
      p.xPos += p.xVel * elapsed / 1000;
      p.yPos += p.yVel * elapsed / 1000;
      p.yVel += elapsed * GRAVITY / 1000;

      if (p.el) {
        p.el.setAttribute('style', `transform: translate(${p.xPos}px, ${p.yPos}px)`);
      }
    }

    requestRef.current = requestAnimationFrame(animate);
  };

  useLayoutEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [particles]);

  return (
    <div className='confettiContainer' style={{ color }}>
      {particles.current?.map((p, i) => (
        <span
          key={'particle_' + i}
          className='particle'
          ref={(el) => { p.el = el ?? undefined; }}>♥</span>
      ))}
    </div>
  );
};
