import React, { useEffect, useState } from 'react';

import './timer.css';

type TimerProps = {
  initialSeconds: number;
  pausable?: boolean;
  onComplete: () => void;
};

export const Timer = ({ initialSeconds, onComplete, pausable }: TimerProps) => {
  const [lastUpdate, setLastUpdate] = useState<number>(Date.now());
  const [timeLeft, setTimeLeft] = useState<number>(initialSeconds * 1000);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPaused) {
        setLastUpdate(Date.now());
        return;
      }
      const now = Date.now();
      const newTimeLeft = timeLeft - (now - lastUpdate);
      if (newTimeLeft > 0) {
        setTimeLeft(newTimeLeft);
        setLastUpdate(now);
      } else {
        setTimeLeft(0);
        clearInterval(interval);
        onComplete();
      }
    }, 20);

    return () => {
      clearInterval(interval);
    };
  }, [isPaused]);

  useEffect(() => {
    const initTimeout = setTimeout(() => {
      setIsActive(true);
    }, 100);

    return () => {
      clearTimeout(initTimeout);
    };
  }, []);

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const totalSeconds = Math.max(0, Math.floor(timeLeft / 1000));
  const min = Math.floor(totalSeconds / 60);
  let sec: number | string = totalSeconds % 60;
  if (sec < 10) {
    sec = '0' + sec;
  }

  return (
    <div className={'timer' + (isActive ? ' go' : '')}>
      {`${min}:${sec}`}
      {pausable && (
        <span className='pause' onClick={togglePause}>
          {isPaused
            ? (
                '>'
              )
            : (
                '||'
              )}
        </span>
      )}
    </div>
  );
};
