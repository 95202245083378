import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { WebApp } from './webapp/webapp';

const ROOT_ID = 'webapp-root';

function getOrCreateRootElement () {
  let rootEl = document.getElementById(ROOT_ID);
  if (!rootEl) {
    rootEl = document.createElement('div');
    rootEl.id = ROOT_ID;
    document.body.appendChild(rootEl);
  }
  return rootEl;
}

let root: Root;

function run () {
  if (!root) {
    root = createRoot(getOrCreateRootElement());
  }
  root.render(<WebApp />);
}

run();

if (module.hot) {
  module.hot.accept('./webapp/webapp', function () {
    run();
  });
}
