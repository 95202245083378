import React, { useState, useRef, useCallback, useMemo, SyntheticEvent } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GuessType, MoveType, TIE } from '@shared/constants';
import { PlayerResult, PromptResult, RoomResult, UserResult } from '@shared/api-dto';
import { respondToPrompt, submitMove, updateName } from '../../api/endpoints';
import { RadioData, RadioGroup } from './shared/radio-group';
import { Timer } from './shared/timer';
import { useTeams, getTeamName, useTallies, useFactOrFibRadios, toUnitString } from './shared/room-data-helpers';
import { TeamList } from './shared/team-list';

const MAX_FREE_CHARS = 150;

type PlayerRoomProp = {
  user: UserResult;
  roomData: RoomResult;
}

type UserResponses = Record<string, string>;

export const PlayerRoom = ({ roomData, user }: PlayerRoomProp) => {
  const [searchParams] = useSearchParams();
  const [team1, team2] = useTeams(roomData.players);
  const { scoresByPlayer, prePrompts } = useTallies(roomData);
  const defaultStep = useMemo(() => {
    const responses: Record<string, string> = {};
    roomData.responses.forEach(r => {
      if (r.userGuid === user.guid) {
        responses[r.promptGuid] = r.text;
      }
    });

    let step = 0;
    while (responses[prePrompts[step]?.guid]) {
      ++step;
    }

    if (step === 0) {
      // For name skips, we can start at the first prompt rather than name form.
      return searchParams.get('skip_name') ? 1 : 0;
    }

    // For page reloads, make sure to load the step not yet entered (ie. step + 1).
    return step + 1;
  }, [user.guid, prePrompts, roomData.responses]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stepNumber, setStepNumber] = useState<number>(defaultStep);
  const player = useMemo(() => (
    roomData.players.find(p => p.user.guid === user.guid)
  ), [roomData.players]);

  const curPrompt = prePrompts[stepNumber - 1];
  const isMultiChoice = Boolean(curPrompt?.choices?.length);
  const userResponses: UserResponses = useMemo(() => {
    const responses: UserResponses = {};
    roomData.responses.forEach(r => {
      if (r.userGuid === user.guid) {
        responses[r.promptGuid] = r.text;
      }
    });
    return responses;
  }, [user.guid, roomData.responses]);

  const getLatestPlayerName = () => {
    let name = user.name;
    if (player?.user.name) {
      name = player.user.name;
    }
    return name;
  };

  const onNameComplete = useCallback(() => {
    setStepNumber(1);
  }, []);

  const onChoose = async (text?: string) => {
    setIsLoading(true);
    try {
      setErrorMessage(null);
      text = text?.trim();
      if (!text) {
        throw new Error('Please provide a response');
      }
      await respondToPrompt({
        code: roomData.code,
        guid: curPrompt.guid,
        text
      });
      setStepNumber(stepNumber + 1);
      setIsLoading(false);
    } catch (e: any) {
      setErrorMessage(e.message);
      setIsLoading(false);
    }
  };

  const onBack = () => {
    setStepNumber(stepNumber - 1);
  };

  const getSetupContent = () => {
    if (stepNumber === 0) {
      return <NameForm
        code={roomData.code}
        defaultName={getLatestPlayerName()}
        onComplete={onNameComplete}
      />;
    }

    if (stepNumber > prePrompts.length) {
      if (roomData.isActive && player?.team) {
        return (
          <>
            <h2>{player.user.name}</h2>
            <h3>{`You are on the ${getTeamName(player.team)} team.`}</h3>
            <p style={{ margin: '2rem 0 0' }}><u>{`${getTeamName(player.team)} Team`}</u></p>
            <TeamList team={player.team === 1 ? team1 : team2} scores={scoresByPlayer} />
          </>
        );
      } else {
        return (
          <>
            <h1>Finished!</h1>
            <h3>Waiting for host to start the game.</h3>
            <button className='back' onClick={onBack}>Back</button>
          </>
        );
      }
    }

    return (
      <>
        <h4>{`Step ${stepNumber} of ${prePrompts.length}`}</h4>
        {errorMessage && (<p style={{ color: 'red' }}>{'' + errorMessage}</p>)}
        <h3>{curPrompt.text}</h3>
        {isMultiChoice
          ? (
            <ChoiceForm
              key={curPrompt.text}
              isLoading={isLoading}
              responses={userResponses}
              prompt={curPrompt}
              onBack={onBack}
              onNext={onChoose} />
            )
          : (
            <FreeTextForm
              key={curPrompt.text}
              isLoading={isLoading}
              responses={userResponses}
              prompt={curPrompt}
              onBack={onBack}
              onNext={onChoose} />
            )}
      </>
    );
  };

  const getMoveContent = () => {
    const move = roomData.currentMove;
    if (!move) {
      console.error('rendering move content without move', roomData);
      return null;
    }

    if (move.type === MoveType.SLIDER_ALL_PLAY) {
      return (
        <SliderForm roomData={roomData} player={player} />
      );
    }

    if (user.guid !== move.player?.user.guid) {
      return <p>{`It's ${move.player?.user.name}'s move.`}</p>;
    }

    if (roomData.currentMove?.submitted) {
      return <h3>Submitted! Now check the big screen to see if you were right!</h3>;
    }

    return (
      <>
        <Timer key={'timer' + move.player.user.guid}
          initialSeconds={(roomData?.timeLeft ?? 0) / 1000}
          onComplete={() => console.log('all done!')} />
        <MoveForm roomData={roomData} player={player} />
      </>
    );
  };

  const gameIsStarted = roomData.isActive && roomData.currentMove;

  return (
    <div className={`playerRoom${roomData.isActive ? ' team' + player?.team : ''}`}>
      {gameIsStarted ? getMoveContent() : getSetupContent()}
    </div>
  );
};

type SliderFormProps = {
  roomData: RoomResult;
  player?: PlayerResult;
}

const SliderForm = ({ player, roomData }: SliderFormProps) => {
  const answer = roomData.currentMove?.prompt.answer;
  const [err, setErr] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [value, setValue] = useState<number>(answer?.default ?? 0);

  const response = useMemo(() => (
    roomData.responses.find(r => (
      r.promptGuid === roomData.currentMove?.prompt.guid &&
        r.userGuid === player?.user.guid
    ))
  ), [player, roomData.currentMove, roomData.responses]);

  const onChange = (evt: SyntheticEvent) => {
    setValue(parseInt((evt.target as any).value, 10));
  };

  const onSubmit = async () => {
    try {
      setErr(null);
      setIsLoading(true);
      await respondToPrompt({
        code: roomData.code,
        guid: roomData.currentMove!.prompt.guid,
        text: value.toString()
      });
      setIsLoading(false);
      setIsSubmitted(true);
    } catch (e) {
      console.error('could not save response', e);
      setIsLoading(false);
      setIsSubmitted(false);
      setErr('Could not save response');
    }
  };

  if (response || isSubmitted) {
    return <p>Thank you! Now watch the big screen!</p>;
  }

  if (!answer) {
    return <p>Could not find answer data.</p>;
  }

  return (
    <>
      <Timer key={'timer_all_play'}
        initialSeconds={(roomData?.timeLeft ?? 0) / 1000}
        onComplete={() => console.log('all done!')} />
      {err && <p style={{ color: 'red' }}>{err}</p>}
      <h2>{roomData.currentMove?.prompt.text}</h2>
      <div className='sliderValue'>{toUnitString(value, answer.units)}</div>
      <input
        className='slider'
        type='range'
        min={answer.min}
        max={answer.max}
        value={value}
        onChange={onChange} />
      <button
        className={player?.team === 1 ? 'reverse' : ''}
        disabled={isLoading}
        onClick={onSubmit}
      >
        Submit
      </button>
    </>
  );
};

type MoveFormProps = {
  roomData: RoomResult;
  player?: PlayerResult;
};

const MoveForm = ({ player, roomData }: MoveFormProps) => {
  if (!roomData.currentMove) {
    console.error('cannot render move form without move', roomData);
    return null;
  }

  const code = roomData.code;
  const move = roomData.currentMove;
  const reverse = player?.team === 1;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [err, setErr] = useState<string | undefined>(undefined);
  const [choice, setChoice] = useState<string | null>(null);

  const FoFRadios = useFactOrFibRadios(roomData, choice);

  const radios = useMemo<RadioData[]>(() => {
    return (move.prompt.choices?.map(c => ({
      text: c,
      value: c,
      checked: choice === c
    })) ?? []).concat([{
      text: TIE,
      value: GuessType.MULTIPLE_TIE,
      checked: choice === GuessType.MULTIPLE_TIE
    }]);
  }, [move.prompt.choices, choice]);

  const onSelect = async (c: string) => {
    const prevChoice = choice;
    try {
      setErr(undefined);
      setIsLoading(true);
      setChoice(c);
      /*
      await setMove({
        code,
        promptGuid: move.prompt.guid,
        text: c
      });
      */
      setIsLoading(false);
    } catch (e) {
      console.error('could not set choice', e);
      setIsLoading(false);
      setChoice(prevChoice);
      setErr('Failed to update choice on the server, please try again');
    }
  };

  const submitChoice = async () => {
    try {
      setErr(undefined);
      setIsLoading(true);
      await submitMove({
        code,
        promptGuid: move.prompt.guid,
        text: choice ?? undefined
      });
      setIsLoading(false);
      setIsSubmitted(true);
    } catch (e) {
      console.error('could not submit choice', e);
      setIsLoading(false);
      setIsSubmitted(false);
      setErr('Failed to submit choice to the server, please try again');
    }
  };

  return (
    <>
      {err && <p style={{ color: 'red' }}>{err}</p>}
      <RadioGroup
        disabled={isLoading || isSubmitted}
        onSelect={onSelect}
        radios={move.type === MoveType.FREE_FACT_OR_FIB ? FoFRadios : radios} />
      <button
        className={reverse ? 'reverse' : ''}
        disabled={isSubmitted || isLoading || !choice}
        onClick={submitChoice}>
        Submit
      </button>
    </>
  );
};

type PromptFormProps = {
  isLoading: boolean;
  responses: UserResponses;
  prompt: PromptResult;
  onBack: () => void;
  onNext: (choice?: string) => void;
}

const FreeTextForm = ({ isLoading, responses, prompt, onBack, onNext }: PromptFormProps) => {
  const [value, setValue] = useState<string>(responses[prompt.guid] ?? '');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const onKeyDown = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      onSubmit();
    }
  };

  const onChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(evt.target.value);
  };

  const onSubmit = useCallback(() => {
    onNext(textareaRef.current?.value);
  }, [textareaRef]);

  const left = MAX_FREE_CHARS - value.length;

  return (
    <>
      <p>(Hint: dont include anything that could identify you.)</p>
      <textarea
        maxLength={MAX_FREE_CHARS}
        ref={textareaRef}
        autoFocus
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
      ></textarea>
      <div className={`charCount${left <= 10 ? ' red' : ''}`}>
        <span>{left}</span> chars left.
      </div>
      <div className='flex'>
        <button className='back' onClick={onBack}>Back</button>
        <button disabled={isLoading} onClick={onSubmit}>Submit</button>
      </div>
    </>
  );
};
const ChoiceForm = ({ isLoading, responses, prompt, onBack, onNext }: PromptFormProps) => {
  const [choice, setChoice] = useState<string | undefined>(responses[prompt?.guid]);
  const radios = useMemo(() => {
    return prompt.choices?.map(c => ({
      text: c,
      checked: choice === c
    })) ?? [];
  }, [prompt.choices, choice]);

  const onSubmit = useCallback(() => {
    onNext(choice);
  }, [choice]);

  return (
    <>
      <RadioGroup disabled={isLoading} onSelect={setChoice} radios={radios} />
      <div className='flex'>
        <button className='back' onClick={onBack}>Back</button>
        <button className='next' disabled={isLoading || !choice} onClick={onSubmit}>Next</button>
      </div>
    </>
  );
};

type NameFormProps = {
  code: string; defaultName: string;
  onComplete: () => void;
}

const NameForm = ({ code, defaultName, onComplete }: NameFormProps) => {
  const nameRef = useRef<HTMLInputElement>(null);

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!nameRef.current) {
      console.error('submit too fast');
      return;
    }

    const newName = nameRef.current.value;
    if (newName && newName !== defaultName) {
      try {
        await updateName(code, newName);
      } catch (e: any) {
        console.error('couldnt update name', e);
      }
    }

    onComplete();
  };

  return (
    <form onSubmit={onSubmit}>
      <h1>Welcome to Icebreak!</h1>
      <h4>What should we call you?</h4>
      <div>
        <input autoFocus ref={nameRef} maxLength={14} type="text" placeholder={defaultName} />
      </div>
      <div>
        <button type='submit' className='next'>Submit</button>
      </div>
    </form>
  );
};
