import React, {
  Children,
  isValidElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import './marquee.css';

const INIT_DELAY = 100;
const ANIMATION_DELAY = 300;

type MarqueeItemProps = {
  duration: number;
  children: ReactNode;
}

export const MarqueeItem = ({ children }: MarqueeItemProps) => {
  return <>{children}</>;
};

type WrappedItemProps = MarqueeItemProps & {
  onComplete: () => void;
}

const WrappedItem = ({ onComplete, children, duration }: WrappedItemProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const initTimeout = setTimeout(() => {
      setIsActive(true);
      timeout = setTimeout(() => {
        setIsActive(false);
        setTimeout(onComplete, ANIMATION_DELAY);
      }, Math.max(duration - INIT_DELAY, 0));
    }, INIT_DELAY);

    return () => {
      clearTimeout(initTimeout);
      clearTimeout(timeout);
    };
  }, []);

  return <div className={'marqueeItem' + (isActive ? ' active' : '')}>
    {children}
  </div>;
};

type MarqueeProps = {
  onComplete: () => void;
  children: ReactNode;
};

export const Marquee = ({ onComplete, children }: MarqueeProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [curItem, setCurItem] = useState<number>(0);

  useEffect(() => {
    if (curItem === Children.toArray(children).length) {
      containerRef.current?.classList.remove('active');
      setTimeout(onComplete, ANIMATION_DELAY);
    }
  }, [curItem]);

  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current?.classList.add('active');
      }, 0);
    }
  }, [containerRef]);

  const wrappedChildren = useMemo(() => {
    const onComplete = (i: number) => {
      setCurItem(i + 1);
    };

    return Children.map(children, (c, i) => (
      isValidElement(c)
        ? (
        <WrappedItem {...c?.props} onComplete={onComplete.bind(null, i)} />
          )
        : (c)
    ));
  }, [children]);

  if (!wrappedChildren) {
    return null;
  }

  return (
    <div ref={containerRef} className='marqueeContainer'>
      {wrappedChildren[curItem]}
    </div>
  );
};
