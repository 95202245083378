import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import { useKitchenSink } from './api/hooks';
import { Landing } from './components/landing';
import { Room } from './components/room/base-room';

import './global.css';

export const WebApp = () => {
  const [
    isLoading,
    kitchenSinkError,
    kitchenSink
  ] = useKitchenSink();

  if (isLoading) {
    return <p></p>;
  } else if (kitchenSinkError || !kitchenSink?.user) {
    return (
      <h3 style={{ color: 'red' }}>
        {`Error: ${kitchenSinkError ?? 'Uknown'}`}
      </h3>
    );
  }

  return <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="room/:code" element={<Room user={kitchenSink.user} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </>;
};

const NotFound = () => (
  <h3>Page not found</h3>
);
