import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';

import {
  RoomResult,
  KitchenSinkResult
} from '@shared/api-dto';
import { getKitchenSink } from './endpoints';
import { NetErrorType } from '@shared/constants';

const MAX_RETRIES = 10;
const RECONNECT_DELAY = 200;

export type ApiHookResult<T> = [
  boolean, // isLoading
  React.ReactNode | string | null, // errorMessage
  T | null // apiResponse
];

export function useKitchenSink (): ApiHookResult<KitchenSinkResult> {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [kitchenSink, setKitchenSink] = React.useState<KitchenSinkResult | null>(null);

  React.useEffect(() => {
    (async () => {
      try {
        const result = await getKitchenSink();
        setIsLoading(false);
        setKitchenSink(result);
      } catch (e: any) {
        setIsLoading(false);
        setError(e);
      }
    })();
  }, []);

  return [isLoading, error, kitchenSink];
}

export function useRoomData (code?: string): ApiHookResult<RoomResult> {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [roomData, setRoomData] = React.useState<RoomResult | null>(null);
  const [retries, setRetries] = React.useState<number>(0);

  React.useEffect(() => {
    if (!code) {
      setError('No code found');
      return;
    }

    let evtSource: EventSource;
    const onMessage = (evt: MessageEvent) => {
      try {
        setRetries(0);
        setError(null);
        setIsLoading(false);
        const result = JSON.parse(evt.data);
        if (result?.error) {
          cleanup();
          if (result.error.type === NetErrorType.KICKED) {
            setError('Sorry, you have been removed from the room.');
            setRoomData(null);
          } else {
            setError('An unkown error occured, please try again later.');
          }
          return;
        }

        setRoomData(result);
      } catch (e) {
        console.error('Unable to process room update');
      }
    };

    const cleanup = () => {
      if (evtSource) {
        evtSource.removeEventListener('update', onMessage as EventListener);
        evtSource.removeEventListener('error', onError as EventListener);
        evtSource.close();
      }
    };

    const onError = (err: any) => {
      console.error('got a push error', retries, err);
      setError('Connection failed, attempting to reconnect...');
      cleanup();
      if (retries <= MAX_RETRIES) {
        setTimeout(() => {
          setRetries(retries + 1);
        }, RECONNECT_DELAY * retries);
      } else {
        setError('Connection failed, please refresh page to try again.');
        setIsLoading(false);
      }
    };

    const connect = () => {
      try {
        setIsLoading(true);
        evtSource = new EventSource(`/join/${code}`, { withCredentials: true });
        evtSource.addEventListener('update', onMessage as EventListener);
        evtSource.addEventListener('error', onError as EventListener);
      } catch (e: any) {
        onError(e);
      }
    };

    connect();

    return cleanup;
  }, [retries]);

  return [isLoading, error, roomData];
}

export function useQRCode (code: string) {
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    (async function () {
      try {
        const url = new URL(`/room/${code}`, window.origin);
        const data = await QRCode.toDataURL(url.toString(), {
          width: 256
        });
        setErrorMsg(null);
        setDataUrl(data);
      } catch (e: any) {
        setDataUrl(null);
        setErrorMsg(e.message ?? 'Failed to QR encode url');
      }
    })();
  }, [code]);

  return [errorMsg, dataUrl];
}
